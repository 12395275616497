<ng-container *appDevEnv>
	@if (formStepOne()) {
		<div>
			<div class="atlas-spacing-mt-4 atlas-spacing-mb-3">
				<atlas-fixed-alert
					header="Envio de Proposta Comercial via WhatsApp"
					type="info"
				>
					Preencha o campo de Celular com um número de WhatsApp válido
					e envie a Proposta Comercial direto para o seu cliente.
				</atlas-fixed-alert>
			</div>

			<form [formGroup]="formStepOne()">
				<h4 class="fw-semibold atlas-spacing-mt-4 mb-0">
					{{ "CLIENT_DATA.TITLE.WHO_WILL_BE_SENT" | translate }}
				</h4>

				<div class="row atlas-spacing-my-3">
					<div class="col-12 col-lg-6">
						<app-select-pill
							[options]="selectPillOptions"
							formControlName="accountType"
							(click)="watchAccounTypeChanges()"
						></app-select-pill>
					</div>
				</div>

				<div class="row g-4">
					@if (selectedAccountNewAccount()) {
						<div class="col-12 col-lg-6">
							<atlas-input
								appAtlasFormElement
								[label]="'CLIENT_DATA.LABEL.CLIENT' | translate"
								[required]="true"
								[placeholder]="
									'CLIENT_DATA.PLACEHOLDER.NEW_CLIENT'
										| translate
								"
								formControlName="razaoSocial"
							></atlas-input>
						</div>
						<div class="col-12 col-lg-6">
							<atlas-masked-input
								appAtlasFormElement
								mask-alias="cpf-cnpj"
								[label]="
									'CLIENT_DATA.LABEL.CPFCNPJ' | translate
								"
								[placeholder]="
									'CLIENT_DATA.PLACEHOLDER.CPFCNPJ'
										| translate
								"
								required="false"
								formControlName="cnpj"
							></atlas-masked-input>
						</div>
						<div class="col-12 col-lg-6">
							<atlas-masked-input
								appAtlasFormElement
								mask-alias="email"
								[label]="'CLIENT_DATA.LABEL.EMAIL' | translate"
								[placeholder]="
									'CLIENT_DATA.PLACEHOLDER.EMAIL' | translate
								"
								required="false"
								formControlName="email"
							>
							</atlas-masked-input>
						</div>

						<div class="col-12 col-lg-6">
							<atlas-masked-input
								appAtlasFormElement
								[label]="
									'CLIENT_DATA.LABEL.CELLPHONE' | translate
								"
								[placeholder]="
									'CLIENT_DATA.PLACEHOLDER.CELLPHONE'
										| translate
								"
								required="false"
								mask-alias="cell-phone"
								formControlName="telefone1"
								[popoverTitle]="'Envio via WhatsApp'"
								[popoverContent]="
									'Insira um número de celular válido para enviar esta proposta comercial'
								"
							>
							</atlas-masked-input>
						</div>
					} @else {
						<div class="col-12 col-lg-6">
							<div>
								<label
									class="atlas-spacing-mb-1"
									for="razaoSocial"
									>Cliente</label
								>
								<ng-select
									id="razaoSocial"
									class="search"
									appendTo="body"
									[items]="companySearchresults$ | async"
									bindLabel="razaoSocial"
									bindValue="razaoSocial"
									[closeOnSelect]="true"
									[hideSelected]="true"
									[typeahead]="input$"
									formControlName="razaoSocial"
									placeholder="Informe o nome, CPF/CNPJ ou email"
									typeToSearchText="Digite para pesquisar"
									notFoundText="Sem resultados"
									loadingText="Carregando..."
									[clearAllText]="
										'GLOBAL.SELECT.CLEAR_ALL' | translate
									"
									[loading]="loadingResults"
									(change)="
										selectedClientTypeaheadOption($event)
									"
								>
									<ng-template
										ng-option-tmp
										let-item="item"
										let-index="index"
									>
										<div class="ms-4 my-2">
											<h6 class="fw-bold">
												{{ item.razaoSocial }}
											</h6>
											<div class="my-2">
												<span class="text-muted">{{
													item.cnpj | cnpj
												}}</span>
											</div>
											<span class="text-muted">{{
												item.email
											}}</span>
										</div>
									</ng-template>
								</ng-select>
							</div>
						</div>

						@if (isTypeaheadSelected()) {
							<div class="col-12 col-lg-6">
								<atlas-masked-input
									appAtlasFormElement
									mask-alias="email"
									[label]="
										'CLIENT_DATA.LABEL.EMAIL' | translate
									"
									[placeholder]="
										'CLIENT_DATA.PLACEHOLDER.EMAIL'
											| translate
									"
									required="false"
									formControlName="email"
								>
								</atlas-masked-input>
							</div>

							<div class="col-12 col-lg-6">
								<atlas-masked-input
									appAtlasFormElement
									mask-alias="cpf-cnpj"
									[label]="
										'CLIENT_DATA.LABEL.CPFCNPJ' | translate
									"
									[placeholder]="
										'CLIENT_DATA.PLACEHOLDER.CPFCNPJ'
											| translate
									"
									required="false"
									formControlName="cnpj"
								>
								</atlas-masked-input>
							</div>

							<div class="col-12 col-lg-6">
								<atlas-masked-input
									appAtlasFormElement
									[label]="
										'CLIENT_DATA.LABEL.CELLPHONE'
											| translate
									"
									[placeholder]="
										'CLIENT_DATA.PLACEHOLDER.CELLPHONE'
											| translate
									"
									required="false"
									mask-alias="cell-phone"
									formControlName="telefone1"
									[popoverTitle]="'Envio via WhatsApp'"
									[popoverContent]="
										'Insira um número de celular válido para enviar esta proposta comercial'
									"
								>
								</atlas-masked-input>
							</div>
						}
					}
				</div>

				@if (showMoreOptions() || selectedAccountNewAccount()) {
					<div>
						<h4
							class="fw-semibold atlas-spacing-mt-4 atlas-spacing-mb-1"
							(click)="isCollapsed = !isCollapsed"
							[attr.aria-expanded]="!isCollapsed"
						>
							{{ "CLIENT_DATA.TITLE.MORE_OPTIONS" | translate }}
							<i
								[ngClass]="{
									'bi-chevron-down': isCollapsed,
									'bi-chevron-up': !isCollapsed
								}"
								class="bi cursor-pointer"
							></i>
						</h4>
						<hr class="atlas-spacing-mt-1 atlas-spacing-mb-3" />
						<div
							id="collapseBasic"
							[collapse]="isCollapsed"
							[isAnimated]="true"
						>
							<div class="row">
								<div class="col-md-6 col-12">
									<app-input
										[labelText]="
											'CLIENT_DATA.LABEL.NAME' | translate
										"
										[placeholderText]="
											'CLIENT_DATA.PLACEHOLDER.NAME'
												| translate
										"
										[isRequired]="true"
										formControlName="nomeFantasia"
										[showErrors]="true"
									></app-input>
								</div>
								<div class="col-md-6 col-12">
									<app-input
										[labelText]="
											'CLIENT_DATA.LABEL.PHONE'
												| translate
										"
										[placeholderText]="
											'CLIENT_DATA.PLACEHOLDER.PHONE'
												| translate
										"
										[isRequired]="true"
										formControlName="telefone2"
										[showErrors]="true"
										mask="(00) 00000-0000 || (00) 0000-0000"
									></app-input>
								</div>
							</div>
							<div class="row atlas-spacing-mt-3">
								<div class="col-md-6 col-12">
									<app-input
										[labelText]="
											'CLIENT_DATA.LABEL.CEP' | translate
										"
										[placeholderText]="
											'CLIENT_DATA.PLACEHOLDER.CEP'
												| translate
										"
										[isRequired]="true"
										formControlName="cep"
										[showErrors]="true"
										(input)="watchCepInputChanges()"
									></app-input>
								</div>
								<div class="col-md-6 col-12">
									<app-input
										[labelText]="
											'CLIENT_DATA.LABEL.CITY' | translate
										"
										[placeholderText]="
											'CLIENT_DATA.PLACEHOLDER.CITY'
												| translate
										"
										[isRequired]="true"
										formControlName="cidade"
										[showErrors]="true"
									></app-input>
								</div>
							</div>
							<div class="row atlas-spacing-mt-3">
								<div class="col-md-6 col-12">
									<app-input
										[labelText]="
											'CLIENT_DATA.LABEL.STREET'
												| translate
										"
										[placeholderText]="
											'CLIENT_DATA.PLACEHOLDER.STREET'
												| translate
										"
										[isRequired]="true"
										formControlName="endereco"
										[showErrors]="true"
									></app-input>
								</div>

								<div class="col-md-6 col-12">
									<app-input
										[labelText]="
											'CLIENT_DATA.LABEL.DISTRICT'
												| translate
										"
										[placeholderText]="
											'CLIENT_DATA.PLACEHOLDER.DISTRICT'
												| translate
										"
										[isRequired]="true"
										formControlName="bairro"
										[showErrors]="true"
									></app-input>
								</div>
							</div>

							<div class="row atlas-spacing-mt-3">
								<div class="col-md-6 col-12">
									<app-input
										[labelText]="
											'CLIENT_DATA.LABEL.ADDRESSNUMBER'
												| translate
										"
										[placeholderText]="
											'CLIENT_DATA.PLACEHOLDER.ADDRESSNUMBER'
												| translate
										"
										[isRequired]="true"
										formControlName="numero"
										[showErrors]="true"
									></app-input>
								</div>
								<div class="col-md-6 col-12">
									<app-input
										[labelText]="
											'CLIENT_DATA.LABEL.ADDRESSCOMPLEMENT'
												| translate
										"
										[placeholderText]="
											'CLIENT_DATA.PLACEHOLDER.ADDRESSCOMPLEMENT'
												| translate
										"
										[isRequired]="true"
										formControlName="complemento"
										[showErrors]="true"
									></app-input>
								</div>
							</div>
						</div>
					</div>
				}
			</form>
		</div>
	}
</ng-container>

<ng-container *appProdEnv>
	<div *ngIf="formStepOne">
		<form [formGroup]="formStepOne()">
			<h4 class="fw-semibold atlas-spacing-mt-4 mb-0">
				{{ "CLIENT_DATA.TITLE.WHO_WILL_BE_SENT" | translate }}
			</h4>

			<div class="row">
				<div class="col-12 col-lg-6 atlas-spacing-mt-3">
					<app-select-pill
						[options]="selectPillOptions"
						formControlName="accountType"
						(click)="watchAccounTypeChanges()"
					></app-select-pill>

					<div class="atlas-spacing-mt-3">
						<app-input
							*ngIf="selectedAccountNewAccount()"
							#input
							id="razaoSocial"
							formControlName="razaoSocial"
							[labelText]="'CLIENT_DATA.LABEL.CLIENT' | translate"
							[isRequired]="true"
							[showErrors]="true"
							[placeholderText]="
								'CLIENT_DATA.PLACEHOLDER.NEW_CLIENT' | translate
							"
						></app-input>

						<div *ngIf="!selectedAccountNewAccount()">
							<label class="atlas-spacing-mb-1" for="razaoSocial"
								>Cliente</label
							>
							<ng-select
								id="razaoSocial"
								class="search"
								appendTo="body"
								[items]="companySearchresults$ | async"
								bindLabel="razaoSocial"
								bindValue="razaoSocial"
								[closeOnSelect]="true"
								[hideSelected]="true"
								[typeahead]="input$"
								formControlName="razaoSocial"
								placeholder="Informe o nome, CPF/CNPJ ou email"
								typeToSearchText="Digite para pesquisar"
								notFoundText="Sem resultados"
								loadingText="Carregando..."
								[clearAllText]="
									'GLOBAL.SELECT.CLEAR_ALL' | translate
								"
								[loading]="loadingResults"
								(change)="selectedClientTypeaheadOption($event)"
							>
								<ng-template
									ng-option-tmp
									let-item="item"
									let-index="index"
								>
									<div class="ms-4 my-2">
										<h6 class="fw-bold">
											{{ item.razaoSocial }}
										</h6>
										<div class="my-2">
											<span class="text-muted">{{
												item.cnpj | cnpj
											}}</span>
										</div>
										<span class="text-muted">{{
											item.email
										}}</span>
									</div>
								</ng-template>
							</ng-select>
						</div>
					</div>
				</div>
				<div class="col-12 col-lg-6"></div>
			</div>

			<div
				*ngIf="showMoreOptions() || selectedAccountNewAccount()"
				class="row mt-4"
			>
				<div class="col-md-4 col-12">
					<app-input
						[labelText]="'CLIENT_DATA.LABEL.CPFCNPJ' | translate"
						[placeholderText]="
							'CLIENT_DATA.PLACEHOLDER.CPFCNPJ' | translate
						"
						[isRequired]="false"
						formControlName="cnpj"
						[showErrors]="true"
						mask="000.000.000-00||00.000.000/0000-00"
					></app-input>
				</div>
				<div class="col-md-4 col-12">
					<app-input
						[labelText]="'CLIENT_DATA.LABEL.EMAIL' | translate"
						[placeholderText]="
							'CLIENT_DATA.PLACEHOLDER.EMAIL' | translate
						"
						[isRequired]="true"
						formControlName="email"
						type="email"
						[showErrors]="true"
					></app-input>
				</div>
				<div class="col-md-4 col-12">
					<app-input
						[labelText]="'CLIENT_DATA.LABEL.CELLPHONE' | translate"
						[placeholderText]="
							'CLIENT_DATA.PLACEHOLDER.CELLPHONE' | translate
						"
						[isRequired]="false"
						formControlName="telefone1"
						[showErrors]="true"
						mask="(00) 00000-0000"
					></app-input>
				</div>
			</div>

			<div *ngIf="showMoreOptions() || selectedAccountNewAccount()">
				<h4
					class="fw-semibold atlas-spacing-mt-4 atlas-spacing-mb-1"
					(click)="isCollapsed = !isCollapsed"
					[attr.aria-expanded]="!isCollapsed"
				>
					{{ "CLIENT_DATA.TITLE.MORE_OPTIONS" | translate }}
					<i
						[ngClass]="{
							'bi-chevron-down': isCollapsed,
							'bi-chevron-up': !isCollapsed
						}"
						class="bi cursor-pointer"
					></i>
				</h4>
				<hr class="atlas-spacing-mt-1 atlas-spacing-mb-3" />
				<div
					id="collapseBasic"
					[collapse]="isCollapsed"
					[isAnimated]="true"
				>
					<div class="row">
						<div class="col-md-4 col-12">
							<app-input
								[labelText]="
									'CLIENT_DATA.LABEL.NAME' | translate
								"
								[placeholderText]="
									'CLIENT_DATA.PLACEHOLDER.NAME' | translate
								"
								[isRequired]="true"
								formControlName="nomeFantasia"
								[showErrors]="true"
							></app-input>
						</div>
						<div class="col-md-4 col-12">
							<app-input
								[labelText]="
									'CLIENT_DATA.LABEL.PHONE' | translate
								"
								[placeholderText]="
									'CLIENT_DATA.PLACEHOLDER.PHONE' | translate
								"
								[isRequired]="true"
								formControlName="telefone2"
								[showErrors]="true"
								mask="(00) 00000-0000 || (00) 0000-0000"
							></app-input>
						</div>
						<div class="col-md-4 col-12"></div>
					</div>
					<div class="row atlas-spacing-mt-3">
						<div class="col-md-4 col-12">
							<app-input
								[labelText]="
									'CLIENT_DATA.LABEL.CEP' | translate
								"
								[placeholderText]="
									'CLIENT_DATA.PLACEHOLDER.CEP' | translate
								"
								[isRequired]="true"
								formControlName="cep"
								[showErrors]="true"
								(input)="watchCepInputChanges()"
							></app-input>
						</div>
						<div class="col-md-4 col-12">
							<app-input
								[labelText]="
									'CLIENT_DATA.LABEL.CITY' | translate
								"
								[placeholderText]="
									'CLIENT_DATA.PLACEHOLDER.CITY' | translate
								"
								[isRequired]="true"
								formControlName="cidade"
								[showErrors]="true"
							></app-input>
						</div>
						<div class="col-md-4 col-12">
							<app-input
								[labelText]="
									'CLIENT_DATA.LABEL.STREET' | translate
								"
								[placeholderText]="
									'CLIENT_DATA.PLACEHOLDER.STREET' | translate
								"
								[isRequired]="true"
								formControlName="endereco"
								[showErrors]="true"
							></app-input>
						</div>
					</div>
					<div class="row atlas-spacing-mt-3">
						<div class="col-md-4 col-12">
							<app-input
								[labelText]="
									'CLIENT_DATA.LABEL.DISTRICT' | translate
								"
								[placeholderText]="
									'CLIENT_DATA.PLACEHOLDER.DISTRICT'
										| translate
								"
								[isRequired]="true"
								formControlName="bairro"
								[showErrors]="true"
							></app-input>
						</div>
						<div class="col-md-4 col-12">
							<app-input
								[labelText]="
									'CLIENT_DATA.LABEL.ADDRESSNUMBER'
										| translate
								"
								[placeholderText]="
									'CLIENT_DATA.PLACEHOLDER.ADDRESSNUMBER'
										| translate
								"
								[isRequired]="true"
								formControlName="numero"
								[showErrors]="true"
							></app-input>
						</div>
						<div class="col-md-4 col-12">
							<app-input
								[labelText]="
									'CLIENT_DATA.LABEL.ADDRESSCOMPLEMENT'
										| translate
								"
								[placeholderText]="
									'CLIENT_DATA.PLACEHOLDER.ADDRESSCOMPLEMENT'
										| translate
								"
								[isRequired]="true"
								formControlName="complemento"
								[showErrors]="true"
							></app-input>
						</div>
					</div>
				</div>
			</div>
		</form>
	</div>
</ng-container>
