import { Injectable } from "@angular/core";
import { BaseCrudBffService } from "@shared/services/base-crud-bff.service";
import { Observable, of } from "rxjs";
import { Hotspot } from "@data/schemas/hotspot/hotspot";

@Injectable({
	providedIn: "root"
})
export class HotspotService {
	private readonly HOTSPOT_ENDPOINT = "hotspot";

	constructor(private baseCrudBff: BaseCrudBffService) {}

	getHotspot(id: string, release: string): Observable<Partial<Hotspot>> {
		return this.baseCrudBff.getAll<Partial<Hotspot>>({
			path: this.HOTSPOT_ENDPOINT,
			params: {
				id,
				release
			}
		});
	}

	saveOrUpdateHotspot(
		hotspot: Partial<Hotspot>
	): Observable<Partial<Hotspot>> {
		if (!hotspot.id) {
			return this.baseCrudBff.save<Partial<Hotspot>>({
				path: this.HOTSPOT_ENDPOINT,
				object: hotspot,
				patch: undefined
			});
		}
		return of({});
	}

	deleteHotspot(id: number): Observable<Partial<Hotspot>> {
		return this.baseCrudBff.delete<Partial<Hotspot>>({
			path: this.HOTSPOT_ENDPOINT,
			id
		});
	}
}
