import { CommonModule } from "@angular/common";
import {
	ChangeDetectionStrategy,
	Component,
	CUSTOM_ELEMENTS_SCHEMA
} from "@angular/core";
import { FormGroup } from "@angular/forms";
import { TranslateModule } from "@ngx-translate/core";
import { AtlasModalService } from "@shared/services/atlas/modal/atlas-modal.service";
import { CommercialProposalStepOneForm } from "../types/form";
import { AutomataTranslateService } from "@shared/services/automata-translate.service";

@Component({
	selector: "app-send-sales-proposal-whatsapp-modal",
	standalone: true,
	imports: [CommonModule, TranslateModule],
	templateUrl: "./send-sales-proposal-whatsapp-modal.component.html",
	styleUrl: "./send-sales-proposal-whatsapp-modal.component.scss",
	schemas: [CUSTOM_ELEMENTS_SCHEMA],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class SendSalesProposalWhatsappModalComponent {
	constructor(
		private window: Window,
		private automataTranslateService: AutomataTranslateService,
		private atlasModalService: AtlasModalService
	) {}

	formData!: FormGroup<CommercialProposalStepOneForm>;
	userCompany = "";
	link = "";

	protected sendToWhatsapp(): void {
		const phoneWhatsapp = `55${this.formData.controls.telefone1.value.replace(/\D/g, "")}`;
		const message = this.automataTranslateService.getTranslationSync(
			"SALES_PROPOSAL.SEND_WHATSAPP_MODAL.MESSAGE_WHATSAPP",
			{
				clientName: this.formData.controls.razaoSocial.value ?? "",
				companyName: this.userCompany,
				link: this.link
			}
		);
		this.window.open(
			`https://wa.me/${phoneWhatsapp}?text=${message}`,
			"_blank",
			"noopener"
		);
		this.atlasModalService.closeModal();
	}

	protected closeModal(): void {
		this.atlasModalService.closeModal();
	}
}
